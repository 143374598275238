import {Permission} from '@oegbv/ui-shared'

export interface ApiDocumentation {
  name: string
  description: string
  path: string
  openApiPath: string
  roles: string[]
  users: string[]
  permissions: Permission[]
}

export const apis: ApiDocumentation[] = [
  {
    name: 'KV',
    description: '',
    path: '/docs/kv',
    openApiPath: '/oegbv-kv.yaml',
    roles: ['CPAdmin'],
    users: [],
    permissions: [],
  },
  {
    name: 'KV-Lohndaten',
    description: '',
    path: '/docs/kv-lohndaten',
    openApiPath: '/oegbv-kv-lohndaten.yaml',
    roles: ['CPAdmin', 'LohndatenApiUser', 'LohndatenApiAdmin'],
    users: [],
    permissions: [Permission.API_LOHNDATEN],
  },
  {
    name: 'Law',
    description: '',
    path: '/docs/law',
    openApiPath: '/oegbv-law.yaml',
    roles: ['CPAdmin'],
    users: [],
    permissions: [],
  },
  {
    name: 'AEM',
    description: '',
    path: '/docs/search-aem',
    openApiPath: '/oegbv-aem.yaml',
    roles: ['CPAdmin'],
    users: [],
    permissions: [],
  },
  {
    name: 'Suche OWCS',
    description: '',
    path: '/docs/suche-owcs',
    openApiPath: '/oegbv-suche-owcs.yaml',
    roles: ['CPAdmin'],
    users: [],
    permissions: [],
  },
  {
    name: 'Indexing',
    description: '',
    path: '/docs/indexing',
    openApiPath: '/oegbv-indexing.yaml',
    roles: ['CPAdmin'],
    users: [],
    permissions: [],
  },
  {
    name: 'Processing Queue',
    description: '',
    path: '/docs/processing-queue',
    openApiPath: '/oegbv-processing-queue.yaml',
    roles: ['CPAdmin'],
    users: [],
    permissions: [],
  },
  {
    name: 'GEMS',
    description: '',
    path: '/docs/gems',
    openApiPath: '/oegbv-gems.yaml',
    roles: ['CPAdmin', 'GEMS'],
    users: [],
    permissions: [],
  },
  {
    name: 'DIS',
    description: '',
    path: '/docs/dis',
    openApiPath: '/oegbv-dis.yaml',
    roles: ['XMLApiUser'],
    users: [],
    permissions: [],
  },
]
